var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("FileUpload", {
        class: _vm.uploaded ? "mt-1 file-input-checked" : "mt-1 file-input",
        attrs: {
          mode: "basic",
          customUpload: true,
          accept:
            ".png,.jpeg,.jpg,.xlsx,.xls,.rtf,.docx,.pptx,.odt,.pdf" ||
            "image/*",
          maxFileSize: 20000000,
          auto: true,
          chooseLabel: _vm.uploaded ? "SUCESSO" : "Buscar arquivo",
        },
        on: { uploader: _vm.getDocuments },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }