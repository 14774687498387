var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Card", {
        staticClass: "card",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", [
                  _c("p", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _c("p", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }