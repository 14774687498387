var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _vm._m(0),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("p", { staticClass: "mt-5 label-upload" }, [
            _vm._v("Carta nomeação"),
          ]),
          _c("OnpointUpload", {
            attrs: { width: "100%" },
            on: { callBackUpload: _vm.enviarCartaNomeacao },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("p", [_vm._v("Carta nomeação")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subtitle" }, [
      _c("p", [_vm._v("Você pode anexar uma carta de nomeação caso tenha.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }