var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      staticClass: "dialog",
      attrs: { visible: _vm.visible, closable: false },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("p", [_vm._v("Adicionar filiar")]),
                  _c("Divider", { staticClass: "divider" }),
                  _c("p", { staticClass: "subtitle mt-10" }, [
                    _vm._v("Informe a filial que deseja incluir."),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                [
                  _c("Button", {
                    staticClass: "cancelButton",
                    attrs: {
                      label: "Cancelar",
                      icon: "pi pi-check",
                      autofocus: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("cancel")
                      },
                    },
                  }),
                  _c("Button", {
                    attrs: {
                      label: "Incluir e salvar filial",
                      icon: "pi pi-check",
                      autofocus: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("confirm")
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "body" }, [
        _c("div", [
          _c("p", { staticClass: "labelInputText" }, [
            _vm._v("\n        Digite o CNPJ da filial.\n        "),
            _c("i", { staticClass: "requiredItem" }, [_vm._v("*")]),
          ]),
          _c("input", {
            ref: "input",
            staticClass: "inputStyle",
            attrs: { type: "text", id: "input" },
            domProps: { value: _vm.value },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event.target.value)
              },
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }