var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filial-card" }, [
    _vm._m(0),
    _vm._m(1),
    _c("div", { staticClass: "mt-5" }, [
      _c(
        "fieldset",
        { staticClass: "fieldset" },
        _vm._l(_vm.filiais, function (filial) {
          return _c(
            "div",
            { key: filial.id, staticClass: "fieldset-content" },
            [
              _c("div", { staticClass: "filial-input" }, [
                _c("input", {
                  attrs: { type: "radio", id: filial.id, name: "filial" },
                  domProps: { value: filial },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("input", filial)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "filial-data" }, [
                _c(
                  "label",
                  { staticClass: "name", attrs: { for: "filial.id" } },
                  [_vm._v(_vm._s(filial.Name))]
                ),
                _c(
                  "label",
                  { staticClass: "document", attrs: { for: "filial.id" } },
                  [_vm._v(_vm._s(filial.CpfCnpj))]
                ),
              ]),
              _c(
                "div",
                { staticClass: "filial-trash" },
                [
                  _c(
                    "Button",
                    {
                      staticClass: "trash-button",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("deleteFilial", filial.UniqueId)
                        },
                      },
                    },
                    [_c("i", { staticClass: "onpoint-trash" })]
                  ),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title mt-8" }, [
      _c("p", [_vm._v("Dados da filial")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subtitle mt-4" }, [
      _c("p", [
        _vm._v(
          "Caso deseje utilizar uma filial, selecione abaixo ou adicione uma."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }