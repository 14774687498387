<template>
  <div>
    <div>
      <div class="title">
        <p>Carta nomeação</p>
      </div>
      <div class="subtitle">
        <p>Você pode anexar uma carta de nomeação caso tenha.</p>
      </div>
      <div class="mt-5">
        <p class="mt-5 label-upload">Carta nomeação</p>
        <OnpointUpload @callBackUpload="enviarCartaNomeacao" width="100%"/>
      </div>
    </div>
  </div>
</template>

<script>
import OnpointUpload from "../../../components/onpoint-upload/";
import { mapActions,mapMutations } from "vuex";
export default {
  props:{
    tomador:Object
  },
  components: {
    OnpointUpload,
  },
  methods:{
    ...mapActions("tomador-module", ["solicitarNomeacaoTomador"]),
    ...mapMutations("proposta-module", [      
      "updateTomadorPropostaSemAlterarLimiteETaxa",
    ]),
    async enviarCartaNomeacao(callback) {
     
      const tomadorSelecionado = this.tomador;      
      await this.$onpoint.loadingModal(
        "Estamos enviando sua carta de nomeação para análise.",
       async () => {  
        const request = {
          PolicyHolderId: tomadorSelecionado.Id,
          NomeationTypeId: 3, //Carta de nomeação
          Files: [
            {
              description:"Carta nomeação",
              name: callback[0].name,
              extension: callback[0].extension,
              uniqueIdentifier: callback[0].uniqueIdentifier,              
              relativePath: callback[0].relativePath,
              size:callback[0].size
            }
          ]
        };
        return await this.solicitarNomeacaoTomador(request).then(() => {
          this.$onpoint.successModal(
            "Carta de nomeação enviada com sucesso. Aguarde a análise da seguradora."
          );
          this.updateTomadorPropostaSemAlterarLimiteETaxa({
            ...tomadorSelecionado,
            HasPendingNomeationProcess: true,
            LastPolicyHolderNomeationRequest: {
              RequestDate: this.$moment()
            }
          });
        })        
        .catch(ex => {
          this.$onpoint.errorModal(ex.response.data.Errors);
        });
      }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.subtitle {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.label-upload{
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;   
}
</style>