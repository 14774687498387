<template>
  <div>
    <table>
      <tr>
        <th>Modalidade</th>
        <th>Limite aprovado</th>
        <th>Limite disponível</th>
        <th>Taxa seguro privado/público</th>
      </tr>
      <tr v-for="item,i in data" :key="i" class="body-table-limits">
        <td class="normal-text-table ">{{item.ModalityGroupName}}</td>
        <td class="limits-style">{{formatCurrency(item.LimitRevised)}}</td>
        <td class="limits-style">{{formatCurrency(item.AvailableLimit)}}</td>
        <td class="normal-text-table">
          <p v-if="item.CanViewLimit">
          {{ item.RiskRate }}% / {{ item.RiskRate }}%
          </p>
        </td>           
      </tr>
      <tr class="total-value-table-limits">
        <td>Limite Total</td>
        <td class="limits-style">{{formatCurrency(maxApprovedLimit)}}</td>
        <td class="limits-style">{{formatCurrency(maxAvailableLimit)}}</td>
        <td></td>        
      </tr>
    </table> 
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    maxApprovedLimit:Number,
    maxAvailableLimit:Number,
  },
  methods:{
    formatCurrency(value, locale = 'pt-BR', currency = 'BRL') {     
      return  String(value).toLocaleString(locale, { style: 'currency', currency: currency });
    }
  }
};
</script>

<style lang="scss">
table{
  width: 100%;  
  border-collapse: separate;  
  border-spacing: 0 10px; 
}
tr{
  text-align: center;
  height: 44px;
  border-radius:10px ;
  margin-block: 20px;
  border: none;
}
th{  
  text-align: center;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}
.limits-style{
   font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 90, 151, 1);
}
.normal-text-table{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(75, 85, 99, 1);
  width:15%
}
.body-table-limits{
  background: rgba(249, 250, 251, 1);
}
.total-value-table-limits{
  text-align: center;
  background: rgba(228, 244, 255, 1);
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
</style>