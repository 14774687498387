<template>
  <div>
    <FileUpload
      :class="uploaded ? 'mt-1 file-input-checked' : 'mt-1 file-input'"
      mode="basic"
      :customUpload="true"
      @uploader="getDocuments"
      :accept="
        '.png,.jpeg,.jpg,.xlsx,.xls,.rtf,.docx,.pptx,.odt,.pdf' || 'image/*'
      "
      :maxFileSize="20000000"
      :auto="true"
      :chooseLabel="uploaded ? 'SUCESSO' : 'Buscar arquivo'"
    >
    </FileUpload>    
  </div>
</template>

<script>
import FileUpload from "primevue/fileupload";
import Instance from "@/axios";

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });
};

export default {
  name: "onpoint-upload",
  props:{
    width:String
  },
  components: {
    FileUpload,
  },
  data() {
    return {
      uploaded: false,
      base64: [],
    };
  },
  mounted() {
    this.cssStyleConfigs();
    window.addEventListener("resize", this.cssStyleConfigs);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.cssStyleConfigs);
  },
  methods: {
    cssStyleConfigs() {  
      if(this.width){
        document.documentElement.style.setProperty("--widthInput",  this.width)
      }  
    },    
    getDocuments(event) {
      // this.base64 = [];
      let newFile = {
        Filename: "",
        FileExtension: "",
        base64: "",
      };

      newFile.Filename = event.files[0].name.split(".")[0];
      newFile.FileExtension = event.files[0].name.split(".").pop();

      getBase64(event.files[0]).then((result) => {
        newFile.base64 = result;
        newFile.base64 = result;
        this.base64.push( newFile);
        this.upload();
      });  
    },
    async sendDocument(document) {
      const payload = {
        FileExtension: document.FileExtension,
        Filename: document.Filename,
        Base64Data: document.base64,
      };
      return await Instance({
        method: "post",
        url: "/api/Archive/UploadBlob",
        data: payload,
      })
      .then((result) => {
          return result
        })
      .catch((errors) => {
        return "Error";
      });
    },
    async upload() {
      let result = "";
      let object = {};
      let totalResult = [];   

      for (let i = this.base64.length - 1; i >= 0; i--) {        

        result = await this.sendDocument(this.base64[i])
        if (result === "Error") {
          return this.$onpoint.errorModal("Algo deu errado, tente novamente");
        }
        
        object = {
          name: result.data.Response.Filename,
          size: result.data.Response.Size,
          uniqueIdentifier: result.data.Response.UniqueId,
          extension: result.data.Response.Extension,
          relativePath: result.data.Response.Filename,
          description: result.data.Response.Description,
        };
        totalResult.push(object);        
        this.uploaded = true;
      }
      setTimeout(() => {
        this.$emit("callBackUpload", totalResult);
      }, 1000);
    },
  },
};
</script>


<style lang="scss">
:root { 
  --widthInput:0px;
}
.file-input-checked {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.07);
  width: var(--widthInput);
  .p-button.p-fileupload-choose {
    content: "SUCESSO";
    background: green;
    color: white;
    border: solid 1px rgba(209, 213, 219, 1);
    border-radius: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  span.p-button-icon {
    display: none;
  }
  input {
    min-width: 100%;
  }
}
.file-input {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.07);
  width: var(--widthInput);
  text-decoration: underline;
  .p-button.p-fileupload-choose {
    background: white;
    color: rgba(75, 85, 99, 1);
    border: solid 1px rgba(209, 213, 219, 1);
    border-radius: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  span.p-button-icon {
    display: none;
  }
}
</style>