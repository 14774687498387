<template>
  <Card class="tomador-card">
    <template #content>
      <div>
        <div class="grid grid-rows-2 grid-cols-3">
          <div class="col-span-2 item">
            <p class="title">Razão social</p>
            <div class="value">
              {{tomador.Name }}
            </div>
          </div>
          <div class="item">
            <p class="title">Cnpj</p>
            <div class="value">
              {{tomador.Cnpj }}
            </div>
          </div>
          <div class="col-span-2 item">
            <p class="title">Endereço principal</p>
            <div class="value">
              {{tomador.Description }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";
export default {
    props:{
        tomador:Object
    },
    components:{
        Card
    }
    
};
</script>

<style lang='scss' scoped>
.tomador-card{  
  background-color: rgba(249, 250, 251, 1);   
  height: 171px;
  padding: 0px;
  box-shadow: none;
  border: none;
  border-radius: 12px;    
  :deep(.p-card-content){
    padding: 0px;
  }
  .title{
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
  .value{
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .item{
    height: 72px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  } 
}
</style>