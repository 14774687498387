<template>
  <div>
    <Card class="card">
        <template #content>
          <div>
            <p class="title">{{title}}</p>
            <p class="text">{{text}}</p>
          </div>
        </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
export default {
    components:{
      Card
    },
    props:{
      title:String,
      text:String
    }
}
</script>

<style lang="scss" scoped>
.card{
  background: rgba(253, 236, 206, 1);
  :deep(.p-card-content){
    padding: 0px;
  }
  .title{
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: black
  }
  .text{
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }   
}
</style>