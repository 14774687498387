var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Card", {
    staticClass: "tomador-card",
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "grid grid-rows-2 grid-cols-3" }, [
                _c("div", { staticClass: "col-span-2 item" }, [
                  _c("p", { staticClass: "title" }, [_vm._v("Razão social")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.tomador.Name) +
                        "\n          "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("p", { staticClass: "title" }, [_vm._v("Cnpj")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.tomador.Cnpj) +
                        "\n          "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-span-2 item" }, [
                  _c("p", { staticClass: "title" }, [
                    _vm._v("Endereço principal"),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.tomador.Description) +
                        "\n          "
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }