var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      staticClass: "dialog",
      attrs: { visible: _vm.visible, closable: false },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("div", { staticClass: "header" }, [
                _c(
                  "div",
                  { staticClass: "title-content" },
                  [
                    _c("p", { staticClass: "title" }, [
                      _vm._v("Solicitar revisão de limites"),
                    ]),
                    _c("Divider", { staticClass: "divider" }),
                  ],
                  1
                ),
                _c("div", { staticClass: "subtitle-content" }, [
                  _c("p", { staticClass: "mt-8 subtitle" }, [
                    _vm._v(
                      "\n          Descreva os motivos e anexe os documentos necessários para solicitar\n          a revisão de limites\n        "
                    ),
                  ]),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("div", { staticClass: "actions" }, [
                _c(
                  "div",
                  { staticClass: "buttons-actions" },
                  [
                    _c("Button", {
                      staticClass: "cancelButton",
                      attrs: {
                        label: "Cancelar",
                        icon: "pi pi-check",
                        autofocus: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("cancel")
                        },
                      },
                    }),
                    _c("Button", {
                      attrs: {
                        label: "Enviar para a seguradora",
                        icon: "pi pi-check",
                        autofocus: "",
                        disabled: _vm.validaDocumentosObrigatorios,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.finalizarPendencia()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "body" }, [
        _c("div", { staticClass: "text-area-content" }, [
          _c(
            "div",
            [
              _c("p", { staticClass: "title-text-area mb-2" }, [
                _vm._v("\n          Motivo da solicitação de revisão "),
                _c("i", { staticClass: "requiredItem" }, [_vm._v("*")]),
              ]),
              _c("Textarea", {
                staticClass: "text-area",
                attrs: { rows: "5", cols: "30", label: "teste" },
                model: {
                  value: _vm.observation,
                  callback: function ($$v) {
                    _vm.observation = $$v
                  },
                  expression: "observation",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "field-inputs-container" },
          _vm._l(_vm.detalhamento, function (detalhe, index) {
            return _c("div", { key: index, staticClass: "mt-10" }, [
              detalhe.StatusName !== "Conforme"
                ? _c(
                    "div",
                    [
                      _c("p", { staticClass: "title-upload" }, [
                        _vm._v("\n          " + _vm._s(detalhe.Name) + " "),
                        _c("i", { staticClass: "requiredItem" }, [_vm._v("*")]),
                      ]),
                      _c("OnpointUpload", {
                        attrs: { width: "585px" },
                        on: {
                          callBackUpload: function ($event) {
                            return _vm.callBackUpload($event, index)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }