<template>
  <div class="filial-card">
    <div class="title mt-8">
      <p>Dados da filial</p>
    </div>
    <div class="subtitle mt-4">
      <p>Caso deseje utilizar uma filial, selecione abaixo ou adicione uma.</p>
    </div>
    <div class="mt-5">
      <fieldset class="fieldset">
        <div
          v-for="filial in filiais"
          :key="filial.id"
          class="fieldset-content"
        >
          <div class="filial-input">
            <input type="radio" :id="filial.id" name="filial" :value="filial" @input="$emit('input', filial)"/>
          </div>

          <div class="filial-data">
            <label for="filial.id" class="name">{{ filial.Name }}</label>
            <label for="filial.id" class="document">{{ filial.CpfCnpj }}</label>
          </div>

          <div class="filial-trash">
            <Button class="trash-button" @click="$emit('deleteFilial', filial.UniqueId)">
              <i class="onpoint-trash"></i>
            </Button>
          </div>          
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";
export default {
  props: {
    filiais: Array,    
  },
  components: {
    Card,
    Button,
  },
  computed:{   

  }
};
</script>

<style lang='scss' scoped>
:deep(.p-card) {
  .p-component {
    padding: 0px;
  }
  .p-card-body {
    padding: 0px;
  }
}
.filial-card {
  background-color: white;
  box-shadow: none;
  border: none;
  border-radius: 12px;

  .title {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .subtitle {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .fieldset {
    border: none;
    padding: 0px;
  }

  .fieldset-content {
    display: flex;
  }

  .filial-input {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(249, 250, 251, 1);
    min-width: 40px;
    border-radius: 8px;
    color: var(--vs-primary);
  }

  .filial-data {
    border-radius: 8px;
    flex-direction: column;
    display: flex;
    background-color: rgba(249, 250, 251, 1);
    padding-block: 12px;
    padding-inline: 24px;
    margin-left: 30px;
    width: 100%;
    .name {
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
    }
    .document {
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .trash-button {
    height: 60px;
    margin-left: 30px;
    width: 40px;
    background: rgba(251, 178, 191, 1);
    border-radius: 8px;
    border: none;
    i:before {
      font-size: 24px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }

  .trash-button:hover {
    background: rgba(251, 178, 191, 0.8);
  }
}
</style>

<style lang="scss" scoped>
</style>